<template>
    <div class="col-lg-8 col-md-12 col-sm-24 col-xs-24 item item-854">
        <a
            class="eventItem"
            :class="event.size === 'l' ? 'eventItem--highlight' : null"
            :href="event.target_page"
            target="_self"
        >
            <div class="eventImage">
                <div
                    v-if="event.imageoverlay"
                    class="additionalInfo"
                    :style="`background-color:${event.bgcolor};color:${event.fontcolor}`"
                >
                    <span>{{ event.imageoverlay }}</span>
                </div>
                <div class="eventBadge logo" v-if="event.size === 'l'">
                    <div class="eventLogo">
                        <img :src="event.logo" />
                    </div>
                </div>
                <div :style="`background-image: url(${event.image})`" class="image"></div>
            </div>
            <div
                :style="`background-color:${event.size === 'l' ? event.color : '#fff'}`"
                class="info"
                :class="event.dark === 'dark' ? 'dark' : null"
            >
                <div class="info-left">
                    <div class="eventBadge logo" v-if="event.logo">
                        <div class="eventLogo">
                            <img :src="event.logo" />
                        </div>
                    </div>
                </div>
                <div class="info-right">
                    <div class="title">{{ event.name }}</div>
                    <div class="eventSubline"></div>
                    <div class="date">
                        <span class="icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
                                <line x1="16" y1="2" x2="16" y2="6"></line>
                                <line x1="8" y1="2" x2="8" y2="6"></line>
                                <line x1="3" y1="10" x2="21" y2="10"></line>
                            </svg>
                        </span>
                        <span>{{ event.dateFrom }}{{ ' - ' + event.dateTo }}</span>
                    </div>
                    <div class="location">
                        <span class="icon">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path>
                                <circle cx="12" cy="10" r="3"></circle>
                            </svg>
                        </span>
                        <span>
                            {{ event.city + ',' }}
                            {{ event.country }}
                        </span>
                    </div>
                </div>

                <div class="info-more">
                    <div class="infoLabel" v-if="event.badgetText">
                        <span>{{ event.badgetText }}</span>
                    </div>
                    <div class="readmore">{{ more }} &gt;</div>
                </div>
            </div>
        </a>
    </div>
</template>

<script>
export default {
    name: 'Card',
    props: {
        event: {
            type: Object,
            required: true,
        },
        more: {
            type: String,
            required: true,
        },
    },
}
</script>

<style scoped></style>
