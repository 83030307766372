<template>
    <div class="tx-mapmaker" v-if="jsonData">
        <section class="eventMap animated">
            <div class="mapFilter">
                <div class="container">
                    <div class="filterMenue">
                        <div class="filter-main">
                            <div class="filterContent">
                                <ul id="filter-year">
                                    <li
                                        class="box-all futureEvents"
                                        @click="setYear()"
                                        :class="futureActive ? null : 'unchecked'"
                                    ><span>{{dataAttributes.lllFuture}}</span></li>
                                    <template v-for="year in jsonData.years" :key="year">
                                        <li
                                            class="item"
                                            v-if="year.year !== 'past'"
                                            :class="year.year === jsonData.selectedYear ? null : 'unchecked'"
                                            @click="setYear(year.year)"
                                        >
                                            {{ year.year === 'past' ? null : year.year }}
                                        </li>
                                    </template>
                                    <li
                                        class="box-all pastEvents"
                                        :class="pastActive ? null : 'unchecked'"
                                        @click="setYear('past')"
                                    ><span>{{dataAttributes.lllPast}}</span></li>
                                </ul>
                            </div>
                        </div>
                        <div class="filter-aside">
                            <div
                                class="dropdown continent"
                                :class="categoryFilter ? 'open' : null"
                                @click="categoryFilter = !categoryFilter"
                            >
                                <h3>{{ dataAttributes.lllRegion }}</h3>
                                <ul id="filter-continent">
                                    <li class="box-all" @click="resetFilters">
                                        {{ dataAttributes.lllAll }}
                                    </li>
                                    <li
                                        v-for="(value, key) in regions"
                                        class="item unchecked"
                                        @click="this.selectedRegion = key"
                                        :key="key"
                                    >
                                        {{ key }} ({{ value }})
                                    </li>
                                </ul>
                            </div>
                            <div
                                class="dropdown category"
                                :class="regionFilter ? 'open' : null"
                                @click="regionFilter = !regionFilter"
                            >
                                <h3>{{ dataAttributes.lllCategory }}</h3>
                                <ul id="filter-category">
                                    <li class="box-all col-md-24 col-xs-24 col-sm-24 col-lg-24" @click="resetFilters">
                                        {{ dataAttributes.lllAll }}
                                    </li>
                                    <li
                                        v-for="(value, key) in category"
                                        @click="this.selectedCategory = key"
                                        class="item box3 unchecked odd col-md-24 col-xs-24 col-sm-24 col-lg-24"
                                        :key="key"
                                    >
                                        {{ key }}&nbsp;({{ value }})
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="filter-breadcrumb" v-if="selectedCategory || selectedRegion">
                <div class="container">
                    <ul class="filterBox">
                        <template v-if="selectedRegion">
                            <li v-for="(value, key) in regions" class="item" :key="key">{{ key }} ({{ value }})</li>
                        </template>
                        <template v-if="selectedCategory">
                            <li
                                v-for="(value, key) in category"
                                class="item box3 odd col-md-24 col-xs-24 col-sm-24 col-lg-24"
                                :key="key"
                            >
                                {{ key }} ({{ value }})
                            </li>
                        </template>
                    </ul>
                    <div class="filter-reset" @click="resetFilters">
                        <div class="icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                                <path
                                    d="M46.67,2.8A1,1,0,0,0,45.56,3L41.31,7.27A23.79,23.79,0,0,0,20.07.36,24,24,0,0,0,1,18a1,1,0,0,0,1,1.28h6.5a1,1,0,0,0,1-.67,16,16,0,0,1,12-10.11,15.3,15.3,0,0,1,2.7-.23,15.56,15.56,0,0,1,11.31,4.84L31.18,17.4a1,1,0,0,0,.72,1.74H46.28a1,1,0,0,0,1-1V3.74A1,1,0,0,0,46.67,2.8Z"
                                ></path>
                                <path
                                    d="M46,28.74h-6.5a1,1,0,0,0-1,.67,16,16,0,0,1-12,10.11,15.3,15.3,0,0,1-2.7.23,15.56,15.56,0,0,1-11.31-4.84l4.31-4.31a1,1,0,0,0-.72-1.74H1.72a1,1,0,0,0-1,1V44.26A1,1,0,0,0,2.44,45l4.25-4.25a23.79,23.79,0,0,0,21.24,6.91A24,24,0,0,0,47,30a1,1,0,0,0-1-1.28Z"
                                ></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section white animated">
            <div class="container">
                <div class="row">
                    <div class="grid">
                        <div class="grid-sizer"></div>
                        <div id="itemContainer">
                            <template v-for="event in jsonData.mapmaker" :key="event">
                                <Card :event="event" :more="dataAttributes.lllMore"></Card>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Card from './components/Card'
// get all data attributes from #vue-event-list element
const $dataAttributes = document.getElementById('vue-event-list').dataset

export default {
    name: 'HelloWorld',
    components: { Card },
    data() {
        return {
            jsonData: null,
            jsonDataUnmutable: null,
            jsonApiUrls: {
                default: '?id=107&typeFilterId=',
                year: '?id=107&typeFilterId=&year=',
                past: '?id=107&year=past&typeFilterId=',
            },
            baseUrl: 'http://interkultur.local/',
            currentYear: null,
            pastActive: false,
            futureActive: false,
            language: 0,
            languageMapping: {
                0: 'en',
                1: 'de',
                2: 'ru',
                3: 'es',
                4: 'it',
                5: 'fr',
            },
            regions: null,
            category: null,
            categoryFilter: false,
            regionFilter: false,
            selectedCategory: null,
            selectedRegion: null,
            dataAttributes: null,
        }
    },
    mounted() {
        // check if current domain is not localhost
        if (window.location.hostname !== 'localhost') {
            console.log('not localhost')
            this.baseUrl = '/'
        } else {
            this.preparePage()
        }
        this.dataAttributes = $dataAttributes
        if (this.dataAttributes.language) {
            this.language = this.dataAttributes.language
        }
        this.getJson(this.baseUrl, this.jsonApiUrls.default)
    },
    watch: {
        selectedCategory: function () {
            this.filterResults()
        },
        selectedRegion: function () {
            this.filterResults()
        },
      categoryFilter: function () {
        // if category filter is active, reset region filter
        if (this.categoryFilter) {
          this.regionFilter = false
          setTimeout(()=>{
            document.addEventListener('click', ()=>{this.categoryFilter = false}, {once: true})
          },250)
        }
      },
      regionFilter: function () {
        // if region filter is active, reset category filter
        if (this.regionFilter) {
          this.categoryFilter = false
          setTimeout(()=>{
            document.addEventListener('click', ()=>{this.regionFilter = false}, {once: true})
          },250)
        }
      },
    },
    methods: {
        getJson(baseUrl, url) {
            if (this.language) {
                url = url + '&L=' + this.language
            }
            fetch(`${baseUrl}${url}`)
                .then((response) => response.json())
                .then((json) => {
                    this.jsonData = json
                    this.jsonDataUnmutable = json
                    console.group('JSON')
                    console.log(this.jsonData)
                    console.groupEnd()
                    this.extractRegion()
                    this.extractEventType()
                    this.resetFilters()
                })
        },
        extractRegion() {
            // log as group
            console.group('Regions')
            // empty regions
            this.regions = {}
            // loop over each key in the object this.jsonData.mapmaker
            for (let key in this.jsonData.mapmaker) {
                // if the key is a region
                if (this.jsonData.mapmaker[key].continent) {
                    // create a new object with the region as key and the value of the region as value
                    let regionName = this.jsonData.mapmaker[key].continent
                    // create object with region as key and count as value
                    this.regions[regionName] = this.regions[regionName] ? this.regions[regionName] + 1 : 1
                }
            }
            // sort the regions object by key
            this.regions = Object.keys(this.regions)
                .sort()
                .reduce((r, k) => ((r[k] = this.regions[k]), r), {})
            console.log(this.regions)
            console.groupEnd()
        },
        extractEventType() {
            // log as group
            console.group('Event Type')
            // empty event type
            this.category = {}
            // loop over each key in the object this.jsonData.mapmaker
            for (let key in this.jsonData.mapmaker) {
                // if the key is a region
                if (this.jsonData.mapmaker[key].category) {
                    // create a new object with the region as key and the value of the region as value
                    let categoryName = this.jsonData.mapmaker[key].category
                    // create object with region as key and count as value
                    this.category[categoryName] = this.category[categoryName] ? this.category[categoryName] + 1 : 1
                }
            }
            // sort the regions by key alphabetically
            this.category = Object.keys(this.category)
                .sort()
                .reduce((r, k) => ((r[k] = this.category[k]), r), {})
            // log the sorted array
            console.log(this.category)
            console.groupEnd()
        },
        filterResults() {
            console.log('filter')
            this.jsonData = JSON.parse(JSON.stringify(this.jsonDataUnmutable))
            // remove all entries that do not match the selected category
            if (this.selectedRegion) {
                for (let key in this.jsonData.mapmaker) {
                    console.log(this.jsonData.mapmaker[key].continent)
                    console.log(this.selectedRegion)
                    // compare the selected region with the region of the current entry
                    if (this.selectedRegion !== this.jsonData.mapmaker[key].continent) {
                        delete this.jsonData.mapmaker[key]
                    }
                }
            }
            if (this.selectedCategory) {
                for (let key in this.jsonData.mapmaker) {
                    console.log(this.jsonData.mapmaker[key].continent)
                    console.log(this.selectedRegion)
                    // compare the selected region with the region of the current entry
                    if (this.selectedCategory !== this.jsonData.mapmaker[key].category) {
                        delete this.jsonData.mapmaker[key]
                    }
                }
            }
            this.extractRegion()
            this.extractEventType()
        },
        resetFilters() {
            this.selectedCategory = null
            this.selectedRegion = null
            this.jsonData = JSON.parse(JSON.stringify(this.jsonDataUnmutable))
            this.extractRegion()
            this.extractEventType()
        },
        preparePage() {
            console.log('preparePage')
            this.cssInjector()
            // add .events to body
            document.body.classList.add('events')
        },
        setYear(year) {
            this.currentYear = year
            this.pastActive = false
            this.futureActive = false
            if (year === 'past') {
                this.getJson(this.baseUrl, this.jsonApiUrls.past)
                this.pastActive = true
            } else if (typeof year === 'number' && year !== 'past') {
                this.getJson(this.baseUrl, this.jsonApiUrls.year + year)
            } else {
                this.futureActive = true
                this.getJson(this.baseUrl, this.jsonApiUrls.default)
            }
        },
        cssInjector() {
            // get all css files from url http://interkultur.local/index.php?id=5 and add them to the head
            fetch('http://interkultur.local/index.php?id=5')
                .then((response) => response.text())
                .then((html) => {
                    const parser = new DOMParser()
                    const doc = parser.parseFromString(html, 'text/html')
                    const css = doc.querySelectorAll('link[rel="stylesheet"]')
                    css.forEach((item) => {
                        const link = document.createElement('link')
                        link.rel = 'stylesheet'
                        link.href = item.href
                        document.head.appendChild(link)
                    })
                })
        },
    },
}
</script>

<style scoped></style>
